import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { BlogKit, Article as ArticleKit } from '@dragonchain-dev/dragon-kit';
import Prism from 'prismjs';
import Layout from '../components/Layout';
import PageMeta from '../components/Meta';

const Article = ({ data: { mdx } }) => {
  useEffect(() => Prism.highlightAll());

  const {
    frontmatter: { categories, description, publishDate, title, slug, featureImage },
    body,
  } = mdx;
  const seoImage = featureImage ? featureImage.childImageSharp.resize : null;

  return (
    <Layout>
      <PageMeta
        article
        pathname={`/blog/${slug}`}
        image={seoImage}
        title={title}
        description={description}
        publishDate={publishDate}
        category={categories[0]}
      />
      <BlogKit url="/blog" theme="light">
        <ArticleKit categories={categories} publishDate={publishDate} title={title}>
          {featureImage && <Img loading="eager" fadeIn={false} fluid={featureImage.childImageSharp.fluid} />}
          <MDXRenderer>{body}</MDXRenderer>
        </ArticleKit>
      </BlogKit>
    </Layout>
  );
};

export default Article;

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        categories
        description
        publishDate(formatString: "MMMM DD, YYYY")
        title
        slug
        featureImage {
          ...FeatureImageFragment
        }
      }
      body
    }
  }

  fragment FeatureImageFragment on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
      resize(width: 1200) {
        src
        height
        width
      }
    }
  }
`;
